import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CARS } from './cars';
import { airport_citis, PLACES, popular_cities } from './places';
import { SITE_SETTINGS } from './site-settings';
import { ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OutstationService } from './outstation.service';

import { environment } from 'src/environments/environment';
import firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'nonstop';

  places = PLACES;
  cars;
  popularCities = popular_cities;
  settings = SITE_SETTINGS;

  constructor(private http: HttpClient,
    private taxiService: OutstationService
  ) { }

  ngOnInit() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }

    this.getTariff();
    
  }

  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.cars = tariff;
        this.taxiService.cars.next(this.cars);
      }
    });
  }


}



  // getPlaceId(cityName) {
  //   const httpPath = `http://localhost:5001/calvincareemailservice/us-central1/webApi/api/v1/getplaces`;

  //   return this.http.post(httpPath, { city: cityName })
  //     .subscribe(res => {
  //       if (res) {
  //         const data = JSON.parse(JSON.stringify(res));
  //         const formatted_address = data.candidates[0].formatted_address;
  //         const place_id = data.candidates[0].place_id;
  //         const name = data.candidates[0].name

  //         this.getPlacesPhotoRef(place_id)
  //           .subscribe(res => {
  //             const data = JSON.parse(JSON.stringify(res));
  //             const photos = data.result.photos.map(pic => pic.photo_reference)

  //             this.placeIds.push({
  //               formatted_address,
  //               place_id,
  //               photos,
  //               name
  //             })
  //           })
  //       }
  //       console.log(this.placeIds);
  //     });
  // }

  // getPlacesPhotoRef(id) {
  //   let httpPath = `http://localhost:5001/calvincareemailservice/us-central1/webApi/api/v1/getplacesid`;
  //   return this.http.post(httpPath, { placeId: id })
  // }
